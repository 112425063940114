import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { loggedIn } from "../../auth/action";
import { useHistory } from "react-router-dom";
import StepLabel from '@material-ui/core/StepLabel';
import { Paper, Typography, Button, CircularProgress, makeStyles, Box, Step, Stepper } from '@material-ui/core';
import SignIn from './LoginForm';
import OTP from './OtpForm';
import { phoneAuth, phoneVerify } from './func'
import { Colors } from "../../colors";
import { ArrowForwardIos as ArrowForwardIosIcon } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
    root: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        border: 'none',
        backgroundColor: '#e71d29'
    },
    paper: {
        position: 'relative',
        height: 400,
        marginTop: 160,
        width: 350,
        margin: '0 auto',
        border: '1px solid white',
        background: '#e71d29',
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            left: 0,
            width: '90%',
            height: 340,
        }
    },
    stepper: {
        background: 'inherit'
    },
    buttons: {
        position: 'absolute',
        bottom: 20,
        right: 20,
    },
    button: {
        width: '100%',
        // marginTop: theme.spacing(10),
        marginLeft: theme.spacing(1),
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    avatar: {
        background: 'none',
        color: 'black'
    }
}))

const steps = ['SignIn', 'OTP'];
function getStepContent(steps: number,
    phoneInput: (phone: string) => any,
    nameInput: (name: string) => any,
    otpInput: (id: string) => any,
    resend: () => any
) {
    switch (steps) {
        case 0:
            return <SignIn phoneInput={phoneInput} nameInput={nameInput} />;
        case 1:
            return <OTP otpInput={otpInput} resend={resend} />;
        default:
            throw new Error('Unknown step');
    }
}
interface State {
    loading: boolean;
    phone: string | null;
    name: string | null;
    code: string | null;
    otp: string | null;
    error: string | null;
    activeStep: number;
}
const defaultState: State = { loading: false, phone: null, name: null, otp: null, error: null, activeStep: 0, code: null }
export default function CheckOut() {
    const history = useHistory()
    const classes = useStyles();
    const dispatch = useDispatch()
    const [state, setState] = useState(defaultState)
    const handleNext = async () => {
        setState({
            ...state,
            loading: true
        })
        if (state.activeStep === 0) {
            if ((!state.phone && !state.name) ||
                !(state.phone && state.phone?.length >= 8 && state.phone?.length <= 11) ||
                !(state.name && state.name.length > 4)
            ) {
                setState({
                    ...state,
                    error: 'Invalid phone Or name'
                })
                return
            }
            const res = await phoneAuth(state.phone ? state.phone : '')
            if (res.success) {
                setState({
                    ...state,
                    error: null,
                    code: res.id || null,
                    activeStep: 1
                })
                return
            }
            setState({
                ...state,
                loading: false,
                error: 'Phone auth failed',
            })
            return
        }
        if (state.activeStep === 1) {
            if (!state.otp || (state.otp && state.otp.length !== 4)) {
                setState({
                    ...state,
                    error: 'Invalid OTP '
                })
            }
            const res = await phoneVerify(state.code || '', state.otp || '')
            if (res.success) {
                dispatch(loggedIn({ name: state.name || '', phone: state.phone || '' }))
                history.push('/')
            } else {
                setState({
                    ...state,
                    loading: false,
                    error: res.message || 'Invalid OTP Code'
                })
            }
        }
    }
    const resendOTP = async () => {
        const res = await phoneAuth(state.phone ? state.phone : '')
        if (res.success) {
            setState({
                ...state,
                error: null,
                code: res.id || null,
                activeStep: 1
            })
            return
        }
    }
    const phoneInput = (phone: string) => {
        setState({
            ...state,
            phone
        })
    }
    const nameInput = (name: string) => {
        setState({
            ...state,
            name
        })
    }
    const otpInput = (id: string) => {
        console.log('verify handler', id)
        setState({
            ...state,
            otp: id
        })
    }
    return (
        <div className={classes.root}>
            <Paper variant='outlined' className={classes.paper} elevation={0}>
                <Box className={classes.header}>
                    <Typography component="h1" variant="h5" align="center"  style={{ color: 'white' }}>
                        SiginIn
                 </Typography>
                </Box>
                <Stepper activeStep={state.activeStep} className={classes.stepper}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div style={{ textAlign: 'center' }}>
                    {state.error && <span style={{ color: 'white', fontSize: 14 }}>{state.error}</span>}
                </div>
                <>
                    {getStepContent(
                        state.activeStep,
                        phoneInput,
                        nameInput,
                        otpInput,
                        resendOTP)}

                    <div className={classes.buttons}>
                        <Button
                            variant="contained"
                            fullWidth={true}
                            style={{
                                backgroundColor: Colors.primary,
                                color: '#e71d29'
                            }}
                            onClick={handleNext}
                            disabled={state.loading ? true : false}
                            className={classes.button}
                            startIcon={
                                state.loading ?
                                    <CircularProgress size={20} style={{ color: 'blanchedalmond' }} /> :
                                    <ArrowForwardIosIcon style={{ color: '#e71d29', }} />}
                        >
                            Next
                </Button>
                    </div>
                </>
            </Paper>
        </div>

    )
}