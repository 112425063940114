import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, TextField, CircularProgress } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import RedoIcon from '@material-ui/icons/Redo';
import Box from '@material-ui/core/Box';
import { Colors } from "../../colors";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '80vh'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    btn: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(3)
    },
    TextField: {
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none"
        }
    }
}));

interface Props {
    otpInput: (id: string) => any
    resend: () => any
}
export default function OTP(props: Props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const resendHandler = () => {
        setLoading(true)
        props.resend()
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <form className={classes.form} noValidate>
                    <TextField
                        className={classes.TextField}
                        margin='dense'
                        variant='filled'
                        type='number'
                        required
                        fullWidth
                        id="otp"
                        label="Enter OTP"
                        name="otp"
                        onChange={e => props.otpInput(e.target.value)}
                        autoFocus
                        style={{ backgroundColor: '#fff' }}
                    />
                    <Box className={classes.btn}>
                        <Button
                            variant='outlined'
                            size='small'
                            style={{ textTransform: 'capitalize', color: Colors.primary, fontWeight: 'bold' }}
                            disabled={loading ? true : false}
                            startIcon={loading ? <CircularProgress size={15} /> : <RedoIcon />}
                            onClick={() => resendHandler()}
                        >
                            Resend OTP
                             </Button>
                    </Box>
                </form>
            </div>
        </Container>
    );
}
