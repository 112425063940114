import * as React from 'react';
import { Link } from "react-router-dom";
import { Typography, makeStyles, Paper } from "@material-ui/core";
import config from "../config";
import { Qrcode } from "../component";
import { LocationCityTwoTone, Phone } from "@material-ui/icons";
import { Colors } from "../colors";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    background: '#e71d29',
    marginBottom: 20,
    marginTop: 120,
    height: '100%',
    padding: '30px 50px',
    margin: '20px 0',
  },
  paper: {
    backgroundColor: '#e71d29',
    padding: 20,
    marginLeft: theme.spacing(40),
    marginTop: theme.spacing(15),
    textAlign: 'center',
    alignContent: 'center',
    paddingBottom: 90,
    width: '50%',
    border: 'none',
    boxShadow: 'none',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      padding: 3,
      width: '100%',
      height: 'auto',
      paddingBottom: 130,
      paddingTop: 60
    }
  },
  contact: {
    padding: 20,
  },
  icon: {
    marginRight: 10,
  },
  copyright: {
    marginTop: '20%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '5%',
    }
  }

}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link to={''} style={{ color: Colors.primary }}>
        <b> {config.businessName}</b>
      </Link>{' '}
      { new Date().getFullYear()}
      { '.'}
    </Typography >
  );
}
export default function A() {
  const classes = useStyles()
  return (
    <Paper className={classes.paper}>
      <Typography variant="h6" gutterBottom>
        {config.businessName}
      </Typography>
      <Typography>
        <Qrcode />
      </Typography>
      <Typography className={classes.contact}>
        <LocationCityTwoTone style={{ color: Colors.primary }} className={classes.icon} />
        {config.contact.address}
      </Typography>
      <Typography className={classes.contact}>
        <Phone style={{ color: Colors.primary }} className={classes.icon} />
        {config.contact.phone}
      </Typography>
      <Typography className={classes.copyright} >
        <Copyright />
      </Typography>
    </Paper>
  );
}

